<template>
  <base-dialog
    ref="dialog"
    type="hidden"
    :title="$t('invoices.multiple')"
    @open="post_open"
  >
    <v-row v-if="!form.type" justify="center">
      <v-col cols="6" v-for="(t, i) in types" :key="i">
        <base-border-card
          @card_clicked="form.type = t"
          :title="$tc(t, 2)"
        ></base-border-card>
      </v-col>
    </v-row>
    <v-row v-else justify="center">
      <v-col cols="4">
        <label>{{ $t("from") }}</label>
        <v-menu
          v-model="menu.from"
          :close-on-content-click="false"
          transition="scale-transition"
          offset-y
          max-width="290px"
          min-width="290px"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              class="daymenu"
              outlined
              dense
              hide-details
              :value="form.from ? $d(new Date(form.from), 'date2digits') : null"
              readonly
              v-bind="attrs"
              v-bind:class="{ 'error--text': form.from > form.to }"
              v-on="on"
            >
              <template v-slot:prepend-inner>
                <v-icon class="ma-1" small>$appointments</v-icon>
              </template>
            </v-text-field>
          </template>
          <v-date-picker
            first-day-of-week="1"
            v-model="form.from"
            color="primary"
            no-title
            @input="menu.to = false"
          ></v-date-picker>
        </v-menu>
      </v-col>
      <v-col cols="4">
        <label>{{ $t("to") }}</label>
        <v-menu
          v-model="menu.to"
          :close-on-content-click="false"
          transition="scale-transition"
          offset-y
          max-width="290px"
          min-width="290px"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              class="daymenu"
              outlined
              dense
              hide-details
              :value="form.to ? $d(new Date(form.to), 'date2digits') : null"
              readonly
              v-bind="attrs"
              v-bind:class="{ 'error--text': form.from > form.to }"
              v-on="on"
            >
              <template v-slot:prepend-inner
                ><v-icon class="ma-1" small>$appointments</v-icon></template
              ></v-text-field
            >
          </template>
          <v-date-picker
            first-day-of-week="1"
            v-model="form.to"
            color="primary"
            no-title
            @input="menu.to = false"
          ></v-date-picker>
        </v-menu>
      </v-col>
      <v-col cols="4" v-if="cashRegisters.length >= 3">
        <label>{{ $t("caja") }}</label>
        <v-select
          outlined
          dense
          v-model="form.cashRegister"
          :items="cashRegisters"
          item-text="name"
          item-value="id"
        ></v-select>
      </v-col>
    </v-row>
    <template v-slot:actions>
      <v-spacer />
      <v-btn
        color="primary"
        height="30px"
        outlined
        @click="$refs.dialog.close()"
        >Cancelar</v-btn
      >
      <v-btn color="primary" height="30px" @click="generate()">Generar</v-btn>
    </template>
  </base-dialog>
</template>
<script>
import { mapActions } from "vuex";
export default {
  name: "GenerateMultipleInvoices",
  data() {
    return {
      types: ["appointment", "economy", "inventory"],
      menu: { from: false, to: false },
      cashRegisters: [],
      form: {
        from: null,
        to: null,
        cashRegister: null,
        type: null,
      },
    };
  },
  methods: {
    open() {
      this.$refs.dialog.open();
    },
    post_open() {
      this.fetchCashRegister();
      this.form = {
        from: null,
        to: null,
        cashRegister: null,
        type: null,
      };
    },
    ...mapActions("cash_register", [
      "getCashRegisters",
      "getDefaultCashRegister",
    ]),
    fetchCashRegister() {
      this.getCashRegisters({ paginator: { itemPerPage: -1 } }).then(
        (response) => {
          this.cashRegisters = [
            { name: "TODAS LAS CAJAS", id: null },
            ...this.$store.getters["auth/filterCashRegisters"](response.data),
          ];
        }
      );
      this.getDefaultCashRegister().then((response) => {
        this.form.cashRegister = response.id;
      });
    },
    ...mapActions("invoices", ["generateInvoices"]),
    generate() {
      console.log(this.form);
      this.generateInvoices(this.form).then((response) => {
        this.$refs.dialog.close();
        this.$emit("generated", response);
      });
    },
  },
};
</script>